import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

var axios = require("axios").default;

var numShares = 216
var ticker = "SHOP"
// var price = getPrice(ticker)
var stockPrice = 679.53

var dict = {
  "2023-02-24": 72,
  "2023-05-24": 18,
  "2023-08-24": 18,
  "2023-11-24": 18,
  "2024-02-24": 18,
  "2024-05-24": 18,
  "2024-08-24": 18,
  "2024-11-24": 18,
  "2025-02-24": 18,
}

function getPrice(ticker) {
  var options = {
    method: 'GET',
    url: `https://yfapi.net/v6/finance/quote?region=US&lang=en&symbols=${ticker}`,
    params: {q: ticker, region: 'US'},
    headers: {
      'accept': 'application/json',
      'X-API-KEY': 'M9ZTBdTOZE3xmFCcn4eBw8MRol6awKox9auHtYSg'
    }
  };
  
  axios.request(options).then(function (response) {
    console.log(response.data);
    var bid = response.data['quoteResponse']['result'][0]['bid']
    var ask = response.data['quoteResponse']['result'][0]['bid']
    var price = (bid + ask)/2
    return price
  }).catch(function (error) {
    console.error(error);
  });
}

class StockTable extends React.Component {
  render() {
    return (
      <div className="stock">
        <div>Ticker: {ticker}, price: {this.props.price}</div>
        <div>Total value: { this.props.price * this.props.shares }</div>
      </div>
    )
  }
}

// ========================================

ReactDOM.render(
  < StockTable shares={numShares} price={stockPrice} />,
  document.getElementById('root')
);
